<template>
  <!-- 针对诺亚方舟二期，在知情同意书之后新加签署劳务协议页面 -->
  <div class="service_agreement_page" v-wechat-title="wechat_title">
    <div id="demo"></div>
    <div class="btn">
        <div class="agree-box">
          <!-- 靶向治疗二期、消化肿瘤2.0需要点击同意阅读 -->
          <van-checkbox v-if="short_name == 'bxzleq' || short_name == 'xhzl'" v-model="checked" :disabled="checkDisabled" icon-size="16px">我已阅读并同意该服务协议</van-checkbox>
        </div>
        <van-button round type="info" size="large" class="up" :disabled="checked?false:((short_name == 'bxzleq' || short_name == 'xhzl') ? true : false)" @click="next">点击签名</van-button>
    </div>
    <div class="goBack" @click="back">
        <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import {
    getSubProjectAgreement,
    subschemeAgre,
    schemeAgre
} from '@/api/api'
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css"
import {
  appHost
} from '@/utils/host'
import {parseUrl} from '@/utils/utils'
export default {
  data() {
    return {
      wechat_title: this.$route.meta.title,
      baseFileUrl: appHost,
      pdfh5: null,
      service_agreement: '', // 医生劳务协议
      short_name: '',
      checkDisabled: true,
      checked: false,
    }
  },
  computed: {
    ...mapState(['userId','basicInformation'])
  },
  mounted() {
    this.short_name = this.basicInformation.projects[0].short_name
    // 慢性常见病学科发展项目显示知情同意书
    if(this.short_name === 'sshy'){
        this.wechat_title = `签署协议`
    }
    this.init()
  },
  methods: {
    async init() {
      // 获取劳务协议
      let {
          items
      } = await getSubProjectAgreement()
      this.pdfh5 = new Pdfh5("#demo", {
          pdfurl: parseUrl(items[0].service_agreement, this.baseFileUrl)
      });
      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
          console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
      })
      this.checkDisabled = false
      // res = await getSubProjectAgreement({
      //     params: {
      //         // id: this.$route.query.subscheme_id,
      //         doctor_id: this.userId
      //     }
      // })
      // console.log("获取服务协议：",res)
    },
    createPdf(val) {
      this.$nextTick(()=>{
          this.pdfh5 = new Pdfh5("#demo", {
              pdfurl: parseUrl(val, this.baseFileUrl)
          });
          //监听完成事件
          this.pdfh5.on("complete", function (status, msg, time) {
              console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
          })
      })
    },
    next() {
      this.$router.replace({
          path: '/sign_service_agreement',
          query: {
            ...this.$route.query
              // scheme_id: this.$route.query.scheme_id,
              // subscheme_id: this.$route.query.subscheme_id,
              // phase: this.$route.query.phase ? this.$route.query.phase : 1,
              // record_id: this.$route.query.record_id ? this.$route.query.record_id : '',
              // is_empower: this.is_empower,
              // is_resign: this.$route.query.is_resign, //判断是否要重新签署医生劳务协议
          }
      })
    },
    back() {
      this.$router.replace({
        path:"/add_agreement",
        query:{
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service_agreement_page{
  height: 100%;
  width: 100%;
  .agree-box {
    line-height: 20px;
    padding: 10px;
    text-align: center !important;
    vertical-align: middle;
      font-size: 14px;
    .van-checkbox {
      justify-content: center;
    }
  }
}
</style>